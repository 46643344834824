import request from '@/utils/request'

// 查询异常订单列表
export function listOrdererror(query) {
	return request({
		url: '/pay/orderError/list',
		method: 'get',
		params: query
	})
}

// 查询异常订单详细
export function getOrdererror(id) {
	return request({
		url: '/pay/orderError/' + id,
		method: 'get'
	})
}

// 新增异常订单
export function addOrdererror(data) {
	return request({
		url: '/pay/orderError',
		method: 'post',
		data: data
	})
}

// 修改异常订单
export function updateOrdererror(data) {
	return request({
		url: '/pay/orderError',
		method: 'put',
		data: data
	})
}

// 删除异常订单
export function delOrdererror(id) {
	return request({
		url: '/pay/orderError/' + id,
		method: 'delete'
	})
}
