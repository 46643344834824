<template>
    <div class="app-container">
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-s-promotion"
                    size="mini"
                    :disabled="multiple"
                    @click="handleUpdate"
                    v-hasPermi="['park:sendError:edit']"
                >重发
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['park:sendError:export']"
                >导出
                </el-button>
            </el-col>
            <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table v-loading="loading" :data="sendErrorList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="id" align="center" prop="id"/>
            <el-table-column label="类型" align="center" prop="type">
                <template slot-scope="scope">
                    <dict-tag :options="dict.type.sys_mq_send_error" :value="scope.row.type"/>
                </template>
            </el-table-column>
            <el-table-column label="内容" align="center" prop="content"/>
            <el-table-column label="状态" align="center" prop="status">
                <template slot-scope="scope">
                    <dict-tag :options="dict.type.sys_common_status" :value="scope.row.status==='0'?'1':'0'"/>
                </template>
            </el-table-column>
            <el-table-column label="重试次数" align="center" prop="tryCount"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-s-promotion"
                        @click="handleUpdate(scope.row)"
                        v-hasPermi="['park:sendError:edit']"
                    >重发
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />

    </div>
</template>

<script>
    import { listSendError, resend} from "@/api/park/sendError";

    export default {
        name: "SendError",
        dicts: ['sys_mq_send_error', 'sys_common_status'],
        data() {
            return {
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // mq发送失败表格数据
                sendErrorList: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    isAsc: 'desc',
                    orderByColumn: 'id'
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {}
            };
        },
        created() {
            this.getList();
        },
        methods: {
            /** 查询mq发送失败列表 */
            getList() {
                this.loading = true;
                listSendError(this.queryParams).then(response => {
                    this.sendErrorList = response.rows;
                    this.total = response.total;
                    this.loading = false;
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    id: null,
                    type: null,
                    content: null,
                    status: "0",
                    tryCount: null,
                    createTime: null
                };
                this.resetForm("form");
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id)
                this.single = selection.length !== 1
                this.multiple = !selection.length
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                let _this = this;
                this.$modal.confirm('是否重发' + (row&&row.id?'"'+row.id+'"':'选中') + '的数据？').then(function () {
                    _this.reset();
                    const id = row.id || _this.ids
                    resend(id).then(response => {
                        _this.$modal.msgSuccess("操作成功");
                        _this.getList();
                    });
                });
            },
            /** 导出按钮操作 */
            handleExport() {
                this.download('park/sendError/export', {
                    ...this.queryParams
                }, `sendError_${new Date().getTime()}.xlsx`)
            }
        }
    };
</script>
