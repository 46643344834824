import request from '@/utils/request'

// 查询mq发送失败列表
export function listSendError(query) {
	return request({
		url: '/park/sendError/list',
		method: 'get',
		params: query
	})
}

// 查询mq发送失败详细
export function resend(id) {
	return request({
		url: '/park/sendError/' + id,
		method: 'get'
	})
}



