import request from '@/utils/request'

// 查询异常停车记录列表
export function listRecordError(query) {
	return request({
		url: '/park/recordError/list',
		method: 'get',
		params: query
	})
}

// 查询异常停车记录详细
export function getRecordError(id) {
	return request({
		url: '/park/recordError/' + id,
		method: 'get'
	})
}

// 新增异常停车记录
export function addRecordError(data) {
	return request({
		url: '/park/recordError',
		method: 'post',
		data: data
	})
}

// 修改异常停车记录
export function updateRecordError(data) {
	return request({
		url: '/park/recordError',
		method: 'put',
		data: data
	})
}

// 删除异常停车记录
export function delRecordError(id) {
	return request({
		url: '/park/recordError/' + id,
		method: 'delete'
	})
}

// 添加灰名单
export function addGreyList(id) {
    return request({
        url: '/park/recordError/addGreyList/' + id,
        method: 'get'
    })
}
