<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
                 label-width="68px">
            <el-form-item label="车牌" prop="carNumber">
                <el-input
                    v-model="queryParams.carNumber"
                    placeholder="请输入车牌"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                <el-button type="warning" plain icon="el-icon-download" size="mini" @click="handleExport" v-hasPermi="['park:multipleFlow:export']">导出
                </el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="multipleFlowList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="id" align="center" prop="id"/>
            <el-table-column label="出入场类型" align="center" prop="inOutType">
                <template slot-scope="scope">
                    <dict-tag :options="dict.type.park_in_out_type" :value="scope.row.inOutType"/>
                </template>
            </el-table-column>
            <el-table-column label="车牌" align="center" prop="carNumber">
                <template slot-scope="scope">
                    <CarColor :carNumber="scope.row.carNumber" :carNumberColor="scope.row.carNumberColor"></CarColor>
                </template>
            </el-table-column>
            <el-table-column label="停车记录id" align="center" prop="recordId"/>
            <el-table-column label="入场时是否超出分组占用泊位数" align="center" prop="overFlag">
                <template slot-scope="scope">
                    {{scope.row.overFlag == '1' ? '是' : '否'}}
                </template>
            </el-table-column>
        </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />

        <!-- 添加或修改多车多泊位停车纪录对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="车、泊位id" prop="carBerthId">
                    <el-input v-model="form.carBerthId" placeholder="请输入车、泊位id"/>
                </el-form-item>
                <el-form-item label="车牌颜色" prop="carNumberColor">
                    <el-input v-model="form.carNumberColor" placeholder="请输入车牌颜色"/>
                </el-form-item>
                <el-form-item label="车牌" prop="carNumber">
                    <el-input v-model="form.carNumber" placeholder="请输入车牌"/>
                </el-form-item>
                <el-form-item label="停车记录id" prop="recordId">
                    <el-input v-model="form.recordId" placeholder="请输入停车记录id"/>
                </el-form-item>
                <el-form-item label="超出标记" prop="overFlag">
                    <el-input v-model="form.overFlag" placeholder="请输入超出标记"/>
                </el-form-item>
                <el-form-item label="删除标志" prop="delFlag">
                    <el-input v-model="form.delFlag" placeholder="请输入删除标志"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        addMultipleFlow,
        delMultipleFlow,
        getMultipleFlow,
        listMultipleFlow,
        updateMultipleFlow
    } from "@/api/park/multipleFlow";

    export default {
        name: "MultipleFlow",
        dicts: ['park_in_out_type'],
        data() {
            return {
                carBerthId:-1,
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // 多车多泊位停车纪录表格数据
                multipleFlowList: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    carBerthId: null,
                    carNumberColor: null,
                    carNumber: null,
                    recordId: null,
                    overFlag: null,
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {}
            };
        },
        created() {
            const query = this.$route.query;
            this.carBerthId = query.carBerthId;
            this.queryParams.carBerthId = this.carBerthId;
            this.getList();
        },
        methods: {
            /** 查询多车多泊位停车纪录列表 */
            getList() {
                this.loading = true;
                listMultipleFlow(this.queryParams).then(response => {
                    this.multipleFlowList = response.rows;
                    this.total = response.total;
                    this.loading = false;
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    id: null,
                    carBerthId: null,
                    carNumberColor: null,
                    carNumber: null,
                    recordId: null,
                    overFlag: null,
                    delFlag: null,
                    createBy: null,
                    createTime: null,
                    updateBy: null,
                    updateTime: null
                };
                this.resetForm("form");
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id)
                this.single = selection.length !== 1
                this.multiple = !selection.length
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                this.open = true;
                this.title = "添加多车多泊位停车纪录";
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                const id = row.id || this.ids
                getMultipleFlow(id).then(response => {
                    this.form = response.data;
                    this.open = true;
                    this.title = "修改多车多泊位停车纪录";
                });
            },
            /** 提交按钮 */
            submitForm() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        if (this.form.id != null) {
                            updateMultipleFlow(this.form).then(response => {
                                this.$modal.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            });
                        } else {
                            addMultipleFlow(this.form).then(response => {
                                this.$modal.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                const ids = row.id || this.ids;
                this.$modal.confirm('是否确认删除多车多泊位停车纪录编号为"' + ids + '"的数据项？').then(function () {
                    return delMultipleFlow(ids);
                }).then(() => {
                    this.getList();
                    this.$modal.msgSuccess("删除成功");
                }).catch(() => {
                });
            },
            /** 导出按钮操作 */
            handleExport() {
                this.download('park/multipleFlow/export', {
                    ...this.queryParams
                }, `multipleFlow_${new Date().getTime()}.xlsx`)
            }
        }
    };
</script>
