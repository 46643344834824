<template>
    <div>
        <el-dropdown trigger="click" placement="bottom" @command="handleCommand">
                <span class="el-dropdown-link" >
                    <svg-icon icon-class="clock" slot="reference" class="screenfull-svg" style="width: 22px;height: 22px;margin-top: 12px;"/>
                    <el-badge :value="counts[0]+counts[1]+counts[2]" class="item" size="mini" style="cursor: pointer;" v-if="counts[0]+counts[1]+counts[2] > 0">
                        <span/>
                    </el-badge>
                </span>
            <el-dropdown-menu slot="dropdown" >
                <el-dropdown-item icon="el-icon-tickets" command="record" v-hasPermi="['park:recordError:list']">停车记录<span style="margin-left: 32px;" ><el-badge is-dot class="item" v-if="counts[0] > 0">{{counts[0] > 0 ? counts[0] : ''}}</el-badge></span></el-dropdown-item>
                <el-dropdown-item icon="el-icon-s-promotion"command="order" v-hasPermi="['pay:orderError:list']">异常订单<el-badge is-dot class="item" style="float: right; " v-if="counts[1] > 0"><span>{{counts[1]}}</span></el-badge></el-dropdown-item>
                <el-dropdown-item icon="el-icon-message" divided command="message" v-hasPermi="['system:notice:list']">通知消息<el-badge is-dot class="item" style="float: right; " v-if="counts[2] > 0"><span>{{counts[2]}}</span></el-badge></el-dropdown-item>
                <el-dropdown-item icon="el-icon-tickets" command="push" v-hasPermi="['park:push:list']">异常推送<span style="margin-left: 32px;" ><el-badge is-dot class="item" v-if="counts[3] > 0">{{counts[3] > 0 ? counts[3] : ''}}</el-badge></span></el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>

    export default {
        name: 'Clock',
        data() {
            return {}
        },
        props: {
            counts: {
                type: Array,
                required: true
            },
        },
        
        methods: {
            handleCommand(command){
                switch (command) {
                    case "record":
                        this.$tab.openPage("异常停车记录", '/park/record-error/list');
                        break;
                    case "order":
                        this.$tab.openPage("异常订单", '/pay/order-error/list');
                        break;
                    case "message":
                        this.$tab.openPage("通知消息", '/system/notice/index');
                        break;
                    case "push":
                        this.$tab.openPage("通知消息", '/park/send-error/list');
                        break;
                }
            }
        }
    }
</script>

<style scoped>
    .screenfull-svg {
        display: inline-block;
        cursor: pointer;
        fill: #5a5e66;;
        width: 20px;
        height: 20px;
        vertical-align: 10px;
    }
</style>
