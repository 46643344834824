<template>
    <div>
        <audio
            controls="controls"
            hidden
            src="../../assets/audio/alarmBell.mp3"
            ref="warningAudio"
            loop
        />
        <el-dialog title="呼叫处理" :visible.sync="open" class="callWarningDialog" :close-on-click-modal="false" width="1450px" :before-close="onDialogClose" append-to-body>
            <el-container class="dialogContainer">
                <el-col :span="9">
                    <!-- <el-row>
                        车场名称: XXXXXXXXXX车场    剩余泊位数:300    设备: XXXXXXXX识别相机 [在线]
                    </el-row> -->
                    <el-card :body-style="{padding: '10px'}">
                        <div slot="header" class="cardHeader">
                            <span>实时监控</span>
                        </div>
                        <video
                            id="curVideo"
                            class="monitorVideo"
                            disablepictureinpicture
                            poster="../../assets/images/noVideo.svg"
                            muted playsinline
                        />
                        <div class="videoMaskContainer" @mouseenter="showVideoMask" @mouseleave="hideVideoMask">
                            <div id="CW_videoMask" class="videoMask">
                                <el-col v-if="videoStatus" :span="5">
                                    <svg-icon class="videoDealBtn" :icon-class="talkStatus ? 'talk_close' : 'talk_open'" @click="openTalkHandle" />
                                    <span class="videoDealBtnLabel">{{talkStatus ? '关闭麦克风' : '打开麦克风'}}</span>
                                </el-col>
                                <el-col :span="5">
                                    <svg-icon class="videoDealBtn" :icon-class="videoStatus ? 'video_close' : 'video_play'" @click="openVideoHandle" />
                                    <span class="videoDealBtnLabel">{{videoStatus ? '关闭视频' : '打开视频'}}</span>
                                </el-col>
                                <el-col :span="5">
                                    <svg-icon class="videoDealBtn" icon-class="fullscreen" @click="fullscreenHandle" />
                                    <span class="videoDealBtnLabel">全屏</span>
                                </el-col>
                            </div>
                        </div>
                    </el-card>
                    <el-card :body-style="{padding: '10px'}">
                        <div slot="header" class="cardHeader">
                            <span>通道数据</span>
                            <el-button
                                class="flowHandleBtn"
                                size="mini"
                                type="text"
                                icon="el-icon-refresh"
                                @click="getLastFlow"
                                v-hasPermi="['park:device:edit']"
                            >刷新数据
                            </el-button>
                            <el-button
                                class="flowHandleBtn"
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click="openFixCarNumberDialog(1)"
                                v-hasPermi="['park:device:edit']"
                            >修改车牌
                            </el-button>
                            <el-button
                                class="flowHandleBtn"
                                size="mini"
                                type="text"
                                icon="el-icon-video-camera"
                                @click="retrigger"
                                v-hasPermi="['park:device:edit']"
                            >重新识别
                            </el-button>
                            <el-button
                                class="flowHandleBtn"
                                size="mini"
                                type="text"
                                icon="el-icon-s-promotion"
                                @click="rePushCarIn"
                                v-hasPermi="['park:device:edit']"
                            >重推入场
                            </el-button>

                        </div>
                        <div v-loading='flowDataLoading'>
                            <el-row class="flowData">
                                <car-color v-if="lastFlowData" :carNumber="lastFlowData.carNumber" :carNumberColor="lastFlowData.carNumberColor"></car-color>
                                <div>{{lastFlowData ? lastFlowData.inoutTime: ""}}</div>
                                <dict-tag v-if="specalCarType.specalType == 1" :options="dict.type.park_car_special_type" :value="specalCarType.carType"/>
                                <dict-tag v-else-if="specalCarType.specalType == 2" :options="dict.type.park_carnumber_list" :value="specalCarType.carType"/>
                                <el-tag v-else>临时车</el-tag>
                            </el-row>
                            <el-row class="flowImg">
                                <img v-if="outImgUrl" height="100%" :src="outImgUrl" alt="">
                                <img v-else width="40%" height="100%" src="../../assets/images/noPic.svg" alt="">
                                <div class="plateImgContainer">
                                    <img class="plateImg" v-if="outPlateImgUrl" :src="outPlateImgUrl" alt="">
                                </div>
                            </el-row>
                        </div>

                    </el-card>

                </el-col>
                <el-col :span="15">
                    <el-row>
                        <el-col class="recordQuery" :span="15">
                            <el-form :model="queryParams" ref="queryForm" size="mini" :inline="true" label-width="68px">
                                <el-form-item label="入场通道" prop="channelInId">
                                    <el-select v-model="queryParams.channelInId" placeholder="请选择入场通道" style="width: 150px" clearable>
                                        <el-option
                                            v-for="channel in channelInList"
                                            :key="channel.id"
                                            :label="channel.channelName"
                                            :value="channel.id"
                                        />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="出场通道" prop="channelOutId">
                                    <el-select v-model="queryParams.channelOutId" placeholder="请选择出场通道" style="width: 150px" clearable>
                                        <el-option
                                            v-for="channel in channelOutList"
                                            :key="channel.id"
                                            :label="channel.channelName"
                                            :value="channel.id"
                                        />
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="入场时间">
                                    <el-date-picker
                                        v-model="inTime"
                                        style="width: 380px"
                                        type="datetimerange"
                                        start-placeholder="入场开始时间"
                                        end-placeholder="入场结束时间"
                                        :default-time="['00:00:00', '23:59:59']"
                                        @change="selectInTime"
                                    ></el-date-picker>
                                </el-form-item>

                                <el-form-item label="出场时间">
                                    <el-date-picker
                                        v-model="outTime"
                                        style="width: 380px"
                                        type="datetimerange"
                                        start-placeholder="出场开始时间"
                                        end-placeholder="出场结束时间"
                                        :default-time="['00:00:00', '23:59:59']"
                                        @change="selectOutTime"
                                    ></el-date-picker>
                                </el-form-item>
                                <el-form-item label="车牌号" prop="carNumber">
                                    <el-input
                                        v-model="queryParams.carNumber"
                                        placeholder="车牌号"
                                        style="width: 125px"
                                        clearable
                                        @keyup.enter.native="handleQuery"
                                    />
                                    <!-- <el-checkbox style="margin-left:10px;" v-model="queryParams.plateFuzzyMatch">模糊匹配</el-checkbox> -->
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :span="9" class="recordImgContainer">
                            <div class="recordImgTab" style="position: absolute">
                                <span :class="recordImgType=='in' ? 'selected' : 'normal'" @click="showRecordImg('in')" >入场</span>
                                <span :class="recordImgType=='out' ? 'selected' : 'normal'" @click="showRecordImg('out')" >出场</span>
                            </div>
                            <img v-if="recordImg" width="100%" height="auto" :src="recordImg" alt="">
                            <img v-else width="40%" height="auto" style="margin-top: 50px; margin-left: 90px" src="../../assets/images/noPic.svg" alt="">
                            <div class="plateImgContainer">
                                <img class="plateImg" v-if="recordPlateImg" :src="recordPlateImg" alt="">
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="padding: 0 0 0 10px">
                        <el-table v-loading="recordLoading" :data="recordList" stripe fit highlight-current-row style="width: auto" height="400" size="mini" @row-click="onRecordClick">
                            <el-table-column label="车牌号" align="center" prop="carNumber" width="105">
                                <template slot-scope="scope">
                                    <car-color :carNumber="scope.row.carNumber" :carNumberColor="scope.row.carNumberColor"></car-color>
                                </template>
                            </el-table-column>
                            <el-table-column label="入场通道" align="center" prop="channelInName"/>
                            <!-- <el-table-column label="车牌颜色" align="center" prop="carNumberColor">

                            </el-table-column> -->
                            <el-table-column label="入场时间" align="center" prop="inTime" width="82">
                                <template slot-scope="scope">
                                    <span>{{ parseTime(scope.row.inTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="出场通道" align="center" prop="channelOutName"/>
                            <el-table-column label="出场时间" align="center" prop="outTime" width="82">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.outTime">{{ parseTime(scope.row.outTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
                                    <span v-else>未出场</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="应收金额" align="center" prop="chargeMoney" width="72">
                                <template slot-scope="scope">
                                    <span>￥{{scope.row.chargeMoney/100}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="优惠金额" align="center" prop="discountMoney" width="72">
                                <template slot-scope="scope">
                                    <span>￥{{scope.row.discountMoney/100}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="实收金额" align="center" prop="paidMoney" width="72">
                                <template slot-scope="scope">
                                    <span>￥{{scope.row.paidMoney/100}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="170" fixed="right">
                                <template slot-scope="scope">
                                    <el-button
                                        size="mini"
                                        type="text"
                                        icon="el-icon-edit"
                                        @click="openFixCarNumberDialog(2, scope.row)"
                                        v-hasPermi="['park:device:edit']"
                                    >修车牌
                                    </el-button>
                                    <el-button
                                        v-if="scope.row.outTime"
                                        size="mini"
                                        type="text"
                                        icon="el-icon-delete"
                                        @click="clearRecordOut(scope.row)"
                                        v-hasPermi="['park:device:remove']"
                                    >清出场
                                    </el-button>
                                    <el-button
                                        v-if="scope.row.outTime"
                                        size="mini"
                                        type="text"
                                        icon="el-icon-edit"
                                        @click="openFixChargeMoneyDialog(scope.row)"
                                        v-hasPermi="['park:device:led:gettemplate']"
                                    >改计费
                                    </el-button>
                                </template>
                            </el-table-column>

                        </el-table>

                        <pagination
                            :total="total"
                            :page.sync="queryParams.pageNum"
                            :limit.sync="queryParams.pageSize"
                            @pagination="getList"
                        />
                    </el-row>
                    <el-row class="handleRegion">
                        <el-col :span="15">
                            <span>问题分类</span>
                            <el-select v-model="dealType" placeholder="请选择类型" clearable style="width: 150px;">
                                <el-option
                                    v-for="dict in dict.type.park_intercom_deal_type"
                                    :key="dict.value"
                                    :label="dict.label"
                                    :value="dict.value"
                                />
                            </el-select>
                            <el-input style="width: 100%; top: 10px;" autocomplete v-model="dealRemark" placeholder="请输入对问题的具体描述"></el-input>
                        </el-col>
                        <el-col :span="6" style="margin-left: 50px">
                            <el-col :span="12">
                                <svg-icon class="dealBtn" icon-class="open_channel" @click="openGateHandle" />
                                <span class="dealBtnLabel">抬杆放行</span>
                            </el-col>
                            <el-col :span="12">
                                <svg-icon class="dealBtn" icon-class="finish" @click="handleFinish" />
                                <span class="dealBtnLabel">处理完成关闭</span>
                            </el-col>
                        </el-col>

                    </el-row>
                </el-col>
            </el-container>
        </el-dialog>

        <el-dialog title="修改车牌" :close-on-click-modal="false" :visible.sync="fixCarNumberOpen" width="350px" append-to-body>
            <el-form ref="carNumberFixParams" :model="carNumberFixParams" label-width="80px">
                <div style="text-align: center;">
                    <img class="fixPlateImg" v-if="carNumberFixParams.plateImg" :src="carNumberFixParams.plateImg" alt="">
                    <span class="fixPlateShowText" v-else alt="">无车牌图</span>
                </div>
                <div class="fixPlateShowText">
                    <car-color :carNumber="carNumberFixParams.carNumber" :carNumberColor="carNumberFixParams.carNumberColor"></car-color>
                </div>
                <el-form-item label="车牌号" prop="carNumber">
                    <el-input v-model="carNumberFixParams.carNumber" placeholder="请输入车牌号"/>
                </el-form-item>
                <el-form-item label="车牌颜色" prop="carNumberColor">
                    <!-- <el-input v-model="carNumberFixParams.carNumberColor" placeholder="请选择车牌颜色"/> -->
                    <el-select v-model="carNumberFixParams.carNumberColor" placeholder="请选择车牌颜色" style="width: 100%">
                        <el-option
                            v-for="dict in dict.type.park_carnumber_color_type"
                            :key="dict.value"
                            :label="dict.label"
                            :value="dict.value"
                        />
                    </el-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="fixCarNumberConfirm">确 定</el-button>
                <el-button @click="fixCarNumberCancel">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改计费" :close-on-click-modal="false" :visible.sync="fixChargeMoneyOpen" width="350px" append-to-body>
            <el-form ref="chargeMoneyFixParams" :model="chargeMoneyFixParams" label-width="100px">
                <div style="text-align: center;">
                    <img class="fixPlateImg" v-if="chargeMoneyFixParams.plateImg" :src="chargeMoneyFixParams.plateImg" alt="">
                    <span class="fixPlateShowText" v-else alt="">无车牌图</span>
                </div>
                <div class="fixPlateShowText">
                    <car-color :carNumber="chargeMoneyFixParams.carNumber" :carNumberColor="chargeMoneyFixParams.carNumberColor"></car-color>
                </div>
                <el-form-item label="入场时间" prop="inTime">
                    <el-input :disabled="true" v-model="chargeMoneyFixParams.inTime" placeholder="无入场时间"/>
                </el-form-item>
                <el-form-item label="出场时间" prop="outTime">
                    <el-input :disabled="true" v-model="chargeMoneyFixParams.outTime" placeholder="无出场时间"/>
                </el-form-item>
                <el-form-item label="停车费（元）" prop="chargeMoney">
                    <el-input type="number" v-model="chargeMoneyFixParams.chargeMoney" placeholder="请输入停车费"/>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="fixChargeMoneyConfirm">确 定</el-button>
                <el-button @click="fixChargeMoneyCancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    listDevice,
    getDevice,
    updateFlowCarNumber,
    requestPole,
    triggerAgain,
    requestVoice,
    requestScreen,
    requestWhiteList,
} from "@/api/park/device";
import {
    listFlow, getFlow
} from '@/api/park/flow';
import {listRecord, closedParkRecordList, clearOutTime, updateCarNumber, updateChargeMoney } from '@/api/park/record';
import {getSpecialmonthByCarNumberAndPark} from '@/api/park/specialmonth';
import {listBlacklist} from '@/api/park/blacklist';
import {listChannel, getChannel, delChannel, addChannel, updateChannel, rePushCarIn} from "@/api/park/channel";
import {getCarImage} from "@/api/park/carImage";
import {intercomNotifyFinish} from '@/api/park/intercom';

import {formatDate} from "@/utils/index";
export default {
    data() {
        return {
            // 视频对讲设备列表
            videoIntercomDeviceList: [],
            // 对讲页面通知消息Map
            viNotifyMap: {},
            // 对讲桌面通知消息Map
            notificationMap: {},
            // 笛虎对讲Websocket的Map
            // dhWsMap: {},
            // 对讲处理弹窗打开状态
            open: false,
            // 修改车牌弹窗打开状态
            fixCarNumberOpen: false,
            // 修改车牌弹框类型 1-通道流水修改车牌； 2-停车记录修改车牌
            fixCarNumberDialogType: 1,
            // 修改停车记录费用弹窗打开状态
            fixChargeMoneyOpen: false,
            // 当前使用的对讲Websocket
            curDhWs: null,
            // 当前使用的对讲设备ID
            curDhId: null,
            // 当前使用的对讲设备
            curDevice: null,
            // 通道最后一条出入场数据图片url
            outImgUrl: null,
            // 出场车牌图
            outPlateImgUrl: null,
            // 通道最后一条出入场流水数据
            lastFlowData: null,
            // 特殊车辆类型
            specalCarType: {
                specalType: 1, // 1-月卡; 2-黑白名单
                carType: null // 车辆类型
            },
            // 通道流水数据loading状态
            flowDataLoading: false,
            carNumberFixParams: {
                plateImg: null,
                carNumber: null,
                carNumberColor: null,
            },
            chargeMoneyFixParams: {
                plateImg: null,
                inTime: null,
                outTime: null,
                carNumber: null,
                carNumberColor: null,
                chargeMoney: null
            },
            //入场时间范围
            inTime: null,
            //出场时间范围
            outTime: null,
            // 停车记录总数
            total: 0,
            // 停车记录列表
            recordList: [],
            // 停车记录loading状态
            recordLoading: false,
            // 处理方式
            dealType: null,
            // 处理备注
            dealRemark: null,
            // 麦克风打开状态
            talkStatus: true,
            // 扬声器打开状态
            audioStatus: false,
            // 视频打开状态
            videoStatus: true,
            // 入场通道列表
            channelInList: [],
            // 出场通道列表
            channelOutList: [],
            // 停车记录查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                // 车牌号
                carNumber: null,
                // 停车场ID
                parkId: null,
                // 入场通道ID
                channelInId: null,
                // 出场通道ID
                channelOutId: null,

                inTimeStart: null,//入场时间范围开始
                inTimeEnd: null,//入场时间范围结束
                outTimeStart: null,//出场时间范围开始
                outTimeEnd: null,//出场时间范围结束
                plateFuzzyMatch: false, //是否模糊匹配
            },
            // 选中停车记录
            selectRecord: null,
            // 当前显示停车记录图片类型
            recordImgType: "in",
            // 当前显示停车记录图片
            recordImg: null,
            // 当前显示停车记录车牌图片
            recordPlateImg: null,
        };
    },
    dicts: [
        "park_device_type",
        "sys_online_type",
        "device_manufacturer",
        "park_channel_type",
        'park_carnumber_color_type',
        'park_car_special_type',
        'park_carnumber_list',
        'park_intercom_deal_type'
    ],
    mounted() {
        this.requestPremForNotification();
        this.refreshToken();
        // this.getLastFlow();
    },
    watch: {
        // 监听对讲初始化状态的变化
        "$store.state.dhWsStore.initStatus": {
            handler() {
                if (this.$store.state.dhWsStore.initStatus == 2) {
                    this.getViDeviceList();
                }
            },
            deep: true,
        },
        "$store.state.dhWsStore.showDevice": {
             handler(val) {
                console.log("showDevice: ", val);
                this.startDealingByDv(val);
            },
            deep: true,
        }
    },
    methods: {
        refreshToken() {
            let that = this;
            let timer = setInterval(() => {
                console.log("RefreshToken")
                if (this.$auth.hasPermi('park:intercom:deal')) {
                    that.$store.dispatch('RefreshToken').then(() => {});
                }
            }, 60*1000)
        },
        requestPremForNotification() {
            if (!("Notification" in window)) {
                alert("不支持Notification")
            }
            Notification.requestPermission(function (permission) {});
        },

        onDictReady() {
            this.getViDeviceList();
        },
        // 获取可视对讲列表
        getViDeviceList() {
            let queryParams = {
                pageNum: 1,
                pageSize: 999999,
                // computer: this.dict.keyValue.device_manufacturer.diihoo,
                deviceType: this.dict.keyValue.park_device_type.videoIntercom,
            };
            listDevice(queryParams).then((response) => {
                this.videoIntercomDeviceList = response.rows;
                this.connectAllViDevice();
            });
        },
        // 可视对讲服务器连接
        connectAllViDevice() {
            for (const vi of this.videoIntercomDeviceList) {
                if (
                    vi.computer ===
                    this.dict.keyValue.device_manufacturer.diihoo
                ) {
                    if (
                        vi.deviceParamObj &&
                        vi.deviceParamObj.serverIp &&
                        vi.deviceParamObj.serverPort &&
                        vi.deviceParamObj.userName &&
                        vi.deviceParamObj.password
                    ) {
                        let serverIp = vi.deviceParamObj.serverIp;
                        let serverPort = vi.deviceParamObj.serverPort;
                        let userName = vi.deviceParamObj.userName;
                        let password = vi.deviceParamObj.password;
                        this.connectDhViDevice(
                            serverIp,
                            serverPort,
                            userName,
                            password,
                            vi
                        );
                    }
                }
            }
        },

        getDhwebKey(serverIp, port, uname) {
            let s = serverIp + port + uname;
            return s;
        },
        onLoginHandle(dhWs, message) {
            this.$store.dispatch("dhWsStore/setInitStatus", 1)
            console.log(message);
            if (message && message.error && message.error === "authfail") {
                let k = this.getDhwebKey(dhWs.serverIp, dhWs.port, dhWs.uname);
                var dhws = this.$store.state.dhWsStore.dhWsMap[k]
                if (dhws) {
                    dhws.logout();
                }
            }
        },
        onNotifyHandle(dhWs, message) {
            if (!this.$auth.hasPermi('park:intercom:deal')) {
                return;
            }

            if (message && message.params) {
                let dhId = message.params.deviceId + "";
                let dhDevice = dhWs.getDevice(dhId);
                if (dhDevice != null && dhDevice.id != null) {
                    let id = dhDevice.id;
                    if (message.params.action == "Start") {
                        if (
                            !(id in this.viNotifyMap) ||
                            this.viNotifyMap[id] == null
                        ) {
                            let n = this.$notify({
                                title: "车场呼叫中",
                                message:
                                    dhDevice.parkName +
                                    "--" +
                                    dhDevice.channelName,
                                type: "warning",
                                position: "bottom-right",
                                offset: 50,
                                showClose: false,
                                duration: 0,
                                onClick: () => {
                                    this.startDealing(dhWs, dhId, dhDevice);

                                    // this.$router.push("/pay/fee-park/list");

                                },
                            });
                            this.browserNotify(dhDevice);
                            if (this.$refs.warningAudio.paused) {
                                this.$refs.warningAudio.currentTime = 0;
                                this.$refs.warningAudio.play()
                            }
                            ;
                            this.viNotifyMap[id] = n;
                        }
                    } else {
                        this.closeNotify(id);
                    }
                }
            }
            console.log(message);

            // setTimeout(this.onNotifyHandle, 60 * 1000, dhWs, message)
        },

        setCurDevice(dhDevice) {
            this.curDevice = dhDevice;
            this.queryParams.parkId = dhDevice.parkId
            this.channelInList = []
            this.channelOutList = []
            listChannel({pageNum: 1, pageSize: 9999, parkId: dhDevice.parkId}).then((response) => {
                if (response.rows && response.rows.length > 0) {
                    for (const r of response.rows) {
                        if (r.inOutType == this.dict.keyValue.park_channel_type.in) {
                            this.channelInList.push(r)
                        } else if (r.inOutType == this.dict.keyValue.park_channel_type.out) {
                            this.channelOutList.push(r)
                        }
                    }
                }
            });
        },

        browserNotify(dhDevice) {
            if (!window.onfocus) {
                var options = {
                    body: "【" + dhDevice.parkName + dhDevice.channelName + "】呼叫中",
                    silent: false,
                    renotify: false,
                    image: "../../assets/images/park_icon.png",
                    noscreen: true,
                };
                var notification = new Notification(process.env.VUE_APP_TITLE, options);
                notification.onclick = function (event) {
                    // console("notification event:", event);
                    alert("notification")
                    notification.close();
                    window.focus()
                    window.open("/")
                    // console("返回浏览器")
                };
                this.notificationMap[dhDevice.id, notification]
                // var defaultTitle = document.title;
            }
        },

        closeNotify(notifyId) {
            this.$refs.warningAudio.pause();
            if (
                notifyId in this.viNotifyMap &&
                this.viNotifyMap[notifyId] != null
            ) {
                this.viNotifyMap[notifyId].close();
                this.viNotifyMap[notifyId] = null;
            }
            if (notifyId in this.notificationMap && this.notificationMap[notifyId] != null) {
                this.notificationMap[notifyId].close();
                this.notificationMap[notifyId] = null;
            }
        },

        onWsConnectErrorHandle(dhWs, error) {
            console.error(
                "[" +
                    dhWs.serverIp +
                    ":" +
                    dhWs.port +
                    "]connect error! reconnect"
            );
            setTimeout(() => {
                dhWs.login();
            }, 60 * 1000);
        },
        connectDhViDevice(serverIp, port, uname, pwd, viDevice) {
            let k = this.getDhwebKey(serverIp, port, uname);
            let dhWs = this.$store.state.dhWsStore.dhWsMap[k];
            if (
                dhWs == null ||
                dhWs.dhAlarmWeb == null
            ) {
                dhWs = this.$createDhWeb(serverIp, port, uname, pwd);
                dhWs.bindDevice(viDevice.serialNo, viDevice);
                dhWs.addCallback("onLogin", this.onLoginHandle);
                dhWs.addCallback("onNotify", this.onNotifyHandle);
                dhWs.addCallback("onDeviceList", (msg) => {
                    console.log("onDeviceList:", msg);
                });
                dhWs.addCallback(
                    "onWsConnectError",
                    this.onWsConnectErrorHandle
                );
                dhWs.addCallback("onPlayRT", (params) => {
                    console.log("onPlayRT:", params);
                });
                dhWs.login();

                this.$store.dispatch("dhWsStore/setDhws", {id:k, dhWs:dhWs})
            } else {
                dhWs.bindDevice(viDevice.serialNo, viDevice);
            }
            this.$store.dispatch("dhWsStore/bindDeviceDhws", {deviceId: viDevice.id, dhWs: dhWs})
        },
        /**
         * 开始处理对讲
         */
        startDealing(dhWs, dhId, dhDevice) {
            if (this.open) {
                this.$modal.msgWarning("有正在处理的呼叫，请处理完成后再接听新呼叫！");
                return;
            }
            this.curDhWs = dhWs;
            this.curDhId = dhId;
            this.setCurDevice(dhDevice);
            this.closeNotify(dhDevice.id);
            this.open = true;
            this.getLastFlow();
            this.$nextTick(() => {
                let i = this.curDhId;
                let cv = document.getElementById(`curVideo`);
                this.hideVideoMask()
                this.curDhWs.playRT(cv, i, true);

            });
        },

        startDealingByDv(dhDevice) {
            console.log("dhDevice: ", dhDevice);
            console.log("dhDevice.id: ", dhDevice.id)

            let dhws = this.$store.state.dhWsStore.deviceDhWsMap[dhDevice.id];
            let dhId = dhDevice.serialNo;
            this.startDealing(dhws, dhId, dhDevice);
        },
        // videoFullscreenHandle() {
        //     let cv = document.getElementById(`curVideo`);
        //     cv.requestFullscreen();
        // },
        /**
         * 打开麦克风处理
         */
        openTalkHandle() {
            if (this.talkStatus) {
                this.curDhWs.dhAlarmWeb.stopTalk(this.curDhId)
            } else {
                this.curDhWs.dhAlarmWeb.startTalk(this.curDhId)
            }
            this.talkStatus = !this.talkStatus
        },
        /**
         * 打开声音处理
         */
        openAudioHandle() {
            if (this.audioStatus) {
                this.curDhWs.dhAlarmWeb.closeAudio(this.curDhId);
            } else {
                this.curDhWs.dhAlarmWeb.openAudio(this.curDhWs.loginHandle, this.curDhId, false, false);
            }
            this.audioStatus = !this.audioStatus
        },
        /**
         * 开启视频处理
         */
        openVideoHandle() {
            let cv = document.getElementById(`curVideo`);
            if (this.videoStatus) {
                this.curDhWs.stopRT(cv, this.curDhId)
                this.showVideoMask()
                cv.src=null;
            } else {
                this.curDhWs.playRT(cv, this.curDhId, this.talkStatus);
            }
            this.videoStatus = !this.videoStatus
        },
        /**
         * 全屏处理
         */
        fullscreenHandle() {
            let cv = document.getElementById(`curVideo`);
            if (!cv) {
                return;
            }
            if (cv.requestFullscreen) {
                cv.requestFullscreen()
            } else if (cv.mozRequestFullScreen) {
                cv.mozRequestFullScreen()
            } else if (cv.webkitRequestFullScreen) {
                cv.webkitRequestFullScreen()
            } else if (cv.mozRequestFullscreen) {
                cv.mozRequestFullscreen()
            }
        },
        /**
         * 开闸处理
         */
        openGateHandle() {
            this.$modal.loading("抬杆处理中");

            let serialNo = -1;
            let flowId = -1;
            if (this.lastFlowData != null) {
                getDevice(this.lastFlowData.deviceId).then(response => {
                    let device = response.data;
                    serialNo = this.lastFlowData.serialNo
                    flowId = this.lastFlowData.id
                    requestPole(device.computer, device.serialNo, flowId).then(response => {
                        this.$modal.msgSuccess(response.msg);
                        this.$modal.closeLoading();
                    })
                });


            } else {
                if (curDevice = null) {
                    this.$modal.msgError("未找到设备");
                }
                let queryParams = {
                    pageNum: 1,
                    pageSize: 999999,
                    channelId: curDevice.channelId,
                    // computer: this.dict.keyValue.device_manufacturer.diihoo,
                    deviceType: this.dict.keyValue.park_device_type.parkCamera,
                };
                listDevice(queryParams).then((response) => {
                    if (response.rows.length <= 0) {
                        this.$modal.msgError("未找到设备");
                        this.$modal.closeLoading();
                    } else {
                        for (const r of response.rows) {
                            requestPole(r.computer, r.serialNo, -1).then(response => {
                                this.$modal.msgSuccess(response.msg);
                                this.$modal.closeLoading();
                            })
                        }
                    }
                });
            }
        },
        /**
         * 结束处理
         */
        handleFinish(e) {
            let cv = document.getElementById(`curVideo`);
            this.curDhWs.stopRT(cv, this.curDhId)
            if (!this.dealType) {
                this.$modal.msgWarning("请选择问题分类");
                return;
            }
            let params = {
                serialNo: this.curDevice.serialNo,
                dealType: this.dealType,
                dealRemark: this.dealRemark
            }

            intercomNotifyFinish(params).then((response) => {
                this.$modal.msgSuccess(response.msg);
                this.initParam()
            }).catch(err => {
                this.$modal.msgError(err);
                this.initParam()
            });

        },

        onDialogClose() {
            let cv = document.getElementById(`curVideo`);
            this.curDhWs.stopRT(cv, this.curDhId)
            this.initParam();
        },

        initParam() {
            this.open = false;
            this.curDhId = null;
            this.curDhWs = null;
            this.lastFlowData = null;
            this.talkStatus = true;
            this.audioStatus = true;
            this.videoStatus = true;
            this.recordList = [];
            this.outImgUrl = null;
            this.outPlateImgUrl = null;
            this.recordImg = null;
            this.recordPlateImg = null;
            this.dealType = null;
            this.dealRemark = null;
            //入场时间范围
            this.inTime = [];
            //出场时间范围
            this.outTime = [];

            this.queryParams.carNumber = null;
            // this.queryParams.parkId = null;
            this.queryParams.channelInId = null;
            this.queryParams.channelOutId = null;

            this.queryParams.inTimeStart = "";
            this.queryParams.inTimeEnd = "";
            this.queryParams.outTimeStart = "";
            this.queryParams.outTimeEnd = "";

            this.queryParams.chargeMoneyMin = 0;//最小应收金额
            this.queryParams.chargeMoneyMax = 0;//最小应收金额
            this.queryParams.paidMoneyMin = 0;//最小实收金额
            this.queryParams.paidMoneyMax = 0;//最大实收金额
            this.queryParams.discountMoneyMin = 0;//最小优惠金额
            this.queryParams.discountMoneyMax = 0;//最大优惠金额
            this.queryParams.plateFuzzyMatch = false;
        },
        /**
         * 获取通道最后一条流水记录
         */
        getLastFlow() {
            this.flowDataLoading = true;
            let queryParams = {
                pageNum: 1,
                pageSize: 1,
                channelId: this.curDevice.channelId,
                orderByColumn: "id",
                isAsc: "desc",
            };
            listFlow(queryParams).then((response) => {
                this.flowDataLoading = false;
                if (response.rows && response.rows.length > 0) {
                    let flowData = response.rows[0]
                    if (this.lastFlowData) {
                        if (this.lastFlowData.id == flowData.id) {
                            this.$message.warning("未获得新数据");
                        } else {
                            this.$message.warning("刷新完成");
                        }
                    }
                    this.lastFlowData = flowData;
                    this.getPicture(this.lastFlowData.imageId);
                    // this.specalCarType = "临时车";
                    this.getSpecialCarType(flowData);
                }

            });
        },
        /**
         * 获取图片
         */
        getPicture(id){
            if (id == null) {
                return;
            }
            getCarImage(id).then(response => {
                let responseData = response.data;
                console.log("picture data: ", response);
                let imgArrStr = responseData.bigImageUrl;
                this.outImgUrl = JSON.parse(imgArrStr)[0];
                this.outPlateImgUrl = responseData.carNumberImageUrl;
            });
        },
        /**
         * 获取特殊车类型
         */
        getSpecialCarType(flowData) {
            this.specalCarType.specalType = null;
            let blacklistParam = {
                carNumber: flowData.carNumber,
                carNumberColor: flowData.carNumberColor,
                parkId: flowData.parkId
            }
            let monthParam = {
                carNumber: flowData.carNumber,
                carNumberColor: flowData.carNumberColor,
                deptId: flowData.parkId
            }
            getSpecialmonthByCarNumberAndPark(monthParam).then((response) => {
                console.log("monthCar: ", response)
                if (response.data != null) {
                    this.specalCarType.specalType = 1;
                    this.specalCarType.carType = response.data.type;
                }
            });
            listBlacklist(blacklistParam).then((response) => {
                console.log("blacklist: ", response)
                if (response.rows && response.rows.length > 0) {
                    this.specalCarType.specalType = 2;
                    this.specalCarType.carType = response.rows[0].type;
                }
            });
        },
        /**
         * 打开修改车牌号界面
         */
        openFixCarNumberDialog(type, recordData) {
            this.fixCarNumberDialogType = type;
            if (type == 1) {
                // 流水数据修改车牌
                if (this.lastFlowData) {
                    this.fixCarNumberOpen = true;
                    this.carNumberFixParams.plateImg = this.outPlateImgUrl
                    this.carNumberFixParams.carNumber = this.lastFlowData.carNumber
                    this.carNumberFixParams.carNumberColor = this.lastFlowData.carNumberColor
                } else {
                    this.$modal.msgError("无通道流水数据");
                }
            } else if (type == 2) {
                // 停车记录数据修改车牌
                if (recordData) {
                    this.selectRecord = recordData

                    this.setRecordImgByFlowId(recordData.flowingIdIn, ()=> {
                        this.fixCarNumberOpen = true;
                        this.carNumberFixParams.plateImg = this.recordPlateImg
                        this.carNumberFixParams.carNumber = recordData.carNumber
                        this.carNumberFixParams.carNumberColor = recordData.carNumberColor
                    });
                } else {
                    this.$modal.msgError("无停车记录");
                }
            }

        },
        fixCarNumberConfirm() {
            if (this.fixCarNumberDialogType == 1) {
                let queryParams = {
                    flowingIdOut: this.lastFlowData.id,
                    carNumber: this.carNumberFixParams.carNumber,
                    carNumberColor: this.carNumberFixParams.carNumberColor
                }
                updateFlowCarNumber(queryParams).then((response) => {
                    this.$modal.msgSuccess(response.msg || "匹配成功");
                    this.getLastFlow();
                }).catch(error => {})
            } else if (this.fixCarNumberDialogType == 2) {
                updateCarNumber(this.selectRecord.id, this.carNumberFixParams.carNumberColor, this.carNumberFixParams.carNumber).then((response) => {
                    this.$modal.msgSuccess(response.msg || "修改成功");
                    let remateParams = {
                        flowingIdOut: this.lastFlowData.id,
                        carNumber: this.carNumberFixParams.carNumber,
                        carNumberColor: this.carNumberFixParams.carNumberColor
                    }
                    updateFlowCarNumber(remateParams).then((response) => {
                        this.$modal.msgSuccess(response.msg || "匹配成功");
                        this.getLastFlow();
                        this.getList()
                    }).catch(error => {})

                }).catch(error => {})
            }

            this.fixCarNumberOpen = false;
        },
        fixCarNumberCancel() {
            this.fixCarNumberOpen = false;
        },
        // 重新触发识别
        retrigger() {
            if (this.lastFlowData != null) {
                let deviceId = this.lastFlowData.deviceId
                triggerAgain(deviceId).then(response => {
                    this.$modal.msgSuccess(response.msg);
                    this.flowDataLoading = true
                    setTimeout(() => {
                        this.getLastFlow();
                    }, 3 * 1000);
                })
            } else {
                if (this.curDevice = null) {
                    this.$modal.msgError("未找到设备");
                }
                let queryParams = {
                    pageNum: 1,
                    pageSize: 999999,
                    channelId: curDevice.channelId,
                    deviceType: this.dict.keyValue.park_device_type.parkCamera,
                };
                listDevice(queryParams).then((response) => {
                    if (response.rows.length <= 0) {
                        this.$modal.msgError("未找到设备");
                    } else {
                        this.flowDataLoading = true
                        for (const r of response.rows) {
                            triggerAgain(r.deviceId).then(response => {
                                this.$modal.msgSuccess(response.msg);
                            })
                        }
                        setTimeout(() => {
                            this.getLastFlow();
                        }, 3 * 1000);
                    }
                });
            }
        },
        /** 重推入场 **/
        rePushCarIn(){
            if (this.curDevice != null) {
                rePushCarIn(this.curDevice.channelId).then(response => {
                    this.$modal.msgSuccess("重推成功");
                });
            }
        },
        /** 入场时间 **/
        selectInTime(time) {
            if (time && time.length > 0) {
                this.queryParams.inTimeStart = formatDate(time[0]);
                this.queryParams.inTimeEnd = formatDate(time[1]);
            } else {
                this.queryParams.inTimeStart = "";
                this.queryParams.inTimeEnd = "";
            }
        },
        /** 出场时间 **/
        selectOutTime(time) {
            if (time && time.length > 0) {
                this.queryParams.outTimeStart = formatDate(time[0]);
                this.queryParams.outTimeEnd = formatDate(time[1]);
            } else {
                this.queryParams.outTimeStart = "";
                this.queryParams.outTimeEnd = "";
            }
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            //入场时间范围
            this.inTime = [];
            //出场时间范围
            this.outTime = [];

            this.queryParams.carNumber = null;
            // this.queryParams.parkId = null;
            this.queryParams.channelInId = null;
            this.queryParams.channelOutId = null;

            this.queryParams.inTimeStart = "";
            this.queryParams.inTimeEnd = "";
            this.queryParams.outTimeStart = "";
            this.queryParams.outTimeEnd = "";

            this.queryParams.chargeMoneyMin = 0;//最小应收金额
            this.queryParams.chargeMoneyMax = 0;//最小应收金额
            this.queryParams.paidMoneyMin = 0;//最小实收金额
            this.queryParams.paidMoneyMax = 0;//最大实收金额
            this.queryParams.discountMoneyMin = 0;//最小优惠金额
            this.queryParams.discountMoneyMax = 0;//最大优惠金额
            this.queryParams.plateFuzzyMatch = false;
            this.resetForm("queryForm");
            this.handleQuery();
        },
        /** 查询停车记录列表 */
        getList() {
            this.recordLoading = true;
            closedParkRecordList(this.queryParams).then(response => {
                response.rows.forEach((item, index) => {
                    item.orders = [];
                })
                this.recordList = response.rows;
                this.total = response.total;
                this.recordLoading = false;
            });
        },

        showRecordImg(type) {
            if (type == 'in') {
                this.recordImgType = type
                if (this.selectRecord) {
                    this.setRecordImgByFlowId(this.selectRecord.flowingIdIn)
                }
            } else if (type == 'out') {
                this.recordImgType = type
                if (this.selectRecord) {
                    this.setRecordImgByFlowId(this.selectRecord.flowingIdOut)
                }
            } else {
                this.recordImgType = "in"
                if (this.selectRecord) {
                    this.setRecordImgByFlowId(this.selectRecord.flowingIdIn)
                }
            }
        },
        /**
         * 点击停车记录table行
         */
        onRecordClick(row, column, event) {
            this.selectRecord = row;
            this.showRecordImg()
        },
        /**
         * 根据flowId获取停车记录图片
         */
        setRecordImgByFlowId(flowId, callback) {
            if (flowId) {
                getFlow(flowId).then((response) => {
                    if (response && response.data && response.data.imageId) {
                        getCarImage(response.data.imageId).then(response => {
                            let responseData = response.data;
                            console.log("picture data: ", response);
                            let imgArrStr = responseData.bigImageUrl;
                            this.recordImg = JSON.parse(imgArrStr)[0];
                            this.recordPlateImg = responseData.carNumberImageUrl;
                            if (callback) {
                                callback()
                            }
                        });
                    }
                })
            } else {
                this.recordImg = null;
                this.recordPlateImg = null;
                if (callback) {
                    callback()
                }
            }

        },
        /**
         * 显示视频遮罩
         */
        showVideoMask() {
            let mask = document.getElementById(`CW_videoMask`);
            mask.style.visibility = "visible";
        },
        /**
         * 隐藏视频遮罩
         */
        hideVideoMask() {
            if (this.videoStatus) {
                let mask = document.getElementById(`CW_videoMask`);
                mask.style.visibility = "hidden";
            }
        },

        clearRecordOut(record) {
            clearOutTime(record.id).then((response) => {
                console.log("clearOutTime: ", response)
                this.getList()
            });
        },

        openFixChargeMoneyDialog(record) {
            this.selectRecord = record
            this.setRecordImgByFlowId(record.flowingIdIn, ()=> {
                this.fixChargeMoneyOpen = true;
                this.chargeMoneyFixParams.plateImg = this.recordPlateImg
                this.chargeMoneyFixParams.inTime = record.inTime
                this.chargeMoneyFixParams.outTime = record.outTime
                this.chargeMoneyFixParams.carNumber = record.carNumber
                this.chargeMoneyFixParams.carNumberColor = record.carNumberColor
                this.chargeMoneyFixParams.chargeMoney = record.chargeMoney/100
            });
        },

        fixChargeMoneyConfirm() {
            updateChargeMoney(this.selectRecord.id, parseInt(this.chargeMoneyFixParams.chargeMoney*100)).then((response) => {
                console.log("updateChargeMoney response: ", response)
                this.$modal.msgSuccess(response.data || "修改成功");
                this.fixChargeMoneyOpen = false;
                this.getList()
            } );

        },
        fixChargeMoneyCancel() {
            this.fixChargeMoneyOpen = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.cardHeader {
    font-size: 16px;
}
.dialogContainer {
    margin: 16px;
}

// .fullscreenBtn {
//     position: relative;
//     text-align: right;
//     margin: -20px 20px 0 0;
// }
.flowHandleBtn {
    margin-left: 20px;
    cursor: pointer;
}

.handleRegion {
    margin: 40px 20px 20px 20px;
}

.videoDealBtn {
    display:block;
    color: white;
    font-size: 48px;
    cursor: pointer;
    text-align: center;
    width: 100% !important;
}
.videoDealBtnLabel {
    display:block;
    color: white;
    cursor: pointer;
    text-align: center;
    width: auto;
    padding: 10px 0;
}

.dealBtn {
    display:block;
    font-size: 48px;
    cursor: pointer;
    text-align: center;
    width: 100% !important;
}
.dealBtnLabel {
    display:block;
    cursor: pointer;
    text-align: center;
    width: auto;
    padding: 10px 0;
}

.flowData {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flowImg {
    margin-top: 20px;
    width: 100%;
    height: 270px;
    text-align: center;
}

.plateImgContainer {
    position: relative;
    top: -15%;
    height: 15%;
    width:100%;
    text-align: left;
}
.plateImg {
    height: 100%;
    width: auto;
}

.fixPlateImg {
    width: 220px;
    height: 70px;
}
.fixPlateShowText {
    width: 100%;
    height: 70px;
    text-align: center;
    font-size: 36px;
    margin-top: 20px;;
}

.recordQuery {
    padding: 0px 0px 0px 25px;
}

.recordImgContainer {
    @include background_color("commonBg2");
    @include border_color("commonBorder4");
    height: 170px;
    // width: auto;
}

.recordImgTab {

    display: flex;
    cursor: pointer;

    padding: 0 5px;
    .selected {
        @include background_color("commonBg4");
        @include border_color("commonBorder5");
        @include font_color("commonFgText");
        padding: 5px;
    }
    .normal {
        @include background_color("commonBg3");
        @include border_color("commonBorder4");
        padding: 5px;
        color: gray;
    }
}

.monitorVideo {
    width: 100%;
    height: 270px
}

.videoMaskContainer {
    position: relative;
    width: 100%;
    height: 270px;
    margin-top: -274px;
}

.videoMask {
    position: relative;
    width: 100%;
    height: 100%;
    background: #1119;
    display: flex;
    justify-content: center;
    align-items: center;
}

//播放按钮
video::-webkit-media-controls-play-button {
    display: none;
}
//进度条
video::-webkit-media-controls-timeline {
    display: none;
}
//观看的当前时间
video::-webkit-media-controls-current-time-display{
    display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
    display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
    display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
    display: none;
}

.el-table .fixed-width .el-button--mini {
    margin: 2px;
}

</style>
<style lang="scss">
.callWarningDialog {
    .el-dialog {
        margin-top: 4vh !important;
    }
    .el-dialog:not(.is-fullscreen) {
        margin-top: 4vh !important;
    }
    .el-dialog__body {
        padding: 0 20px !important;
    }
    .el-dialog__footer {
        padding: 15px !important;
    }
}
</style>
