import request from '@/utils/request'

// 查询通道列表
export function listChannel(query) {
	return request({
		url: '/park/channel/list',
		method: 'get',
		params: query
	})
}

// 查询通道详细
export function getChannel(id) {
	return request({
		url: '/park/channel/' + id,
		method: 'get'
	})
}

// 新增通道
export function addChannel(data) {
	return request({
		url: '/park/channel',
		method: 'post',
		data: data
	})
}

// 返回指定车场所有的通道
export function parkChannel(data) {
    return request({
        url: '/park/channel/park',
        method: 'post',
        data: data
    })
}

// 修改通道
export function updateChannel(data) {
	return request({
		url: '/park/channel',
		method: 'put',
		data: data
	})
}

// 删除通道
export function delChannel(id) {
	return request({
		url: '/park/channel/' + id,
		method: 'delete'
	})
}

// 查询车场通道列表
export function parkChannelList(query) {
    return request({
        url: '/park/channel/parkChannelList',
        method: 'get',
        params: query
    })
}

// 查询车场通道列表
export function rePushCarIn(channelId) {
    return request({
        url: '/park/channel/rePushCarIn/' + channelId,
        method: 'get'
    })
}

// 当前车场通道列表
export function thisParkChannels(parkId) {
    return request({
        url: '/park/channel/thisParkChannels/' + parkId,
        method: 'get'
    })
}
