var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container home" }, [
    _c(
      "div",
      { staticClass: "content-container" },
      [
        _c(
          "el-row",
          { staticClass: "row-padding row1", attrs: { gutter: 36 } },
          [
            _c(
              "el-col",
              { staticStyle: { height: "100%" }, attrs: { span: 12 } },
              [
                _c(
                  "el-row",
                  { staticClass: "row-padding row3", attrs: { gutter: 36 } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "row4", attrs: { span: 18 } },
                      [
                        _c("digital-scroll", {
                          attrs: {
                            digitalType: "1",
                            value: _vm.digitalValue[0],
                            decimals: 2,
                            prefix: "￥",
                            title: "今日停车收入",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "row4", attrs: { span: 6 } },
                      [
                        _c(
                          "el-row",
                          {
                            staticClass: "row-padding row2",
                            attrs: { gutter: 36 },
                          },
                          [
                            _c(
                              "el-col",
                              { staticClass: "row4", attrs: { span: 24 } },
                              [
                                _c("digital-scroll", {
                                  attrs: {
                                    digitalType: "2",
                                    value: _vm.digitalValue[1],
                                    title: "今日车流量",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          {
                            staticClass: "row-padding row2",
                            attrs: { gutter: 36 },
                          },
                          [
                            _c(
                              "el-col",
                              { staticClass: "row4", attrs: { span: 24 } },
                              [
                                _c("digital-scroll", {
                                  attrs: {
                                    digitalType: "2",
                                    value: _vm.digitalValue[2],
                                    title: "今日订单数",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "row-padding row1", attrs: { gutter: 36 } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "row4", attrs: { span: 6 } },
                      [
                        _c("digital-scroll", {
                          attrs: {
                            digitalType: "2",
                            value: _vm.digitalValue[3],
                            title: "封闭车场数",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "row4", attrs: { span: 6 } },
                      [
                        _c("digital-scroll", {
                          attrs: {
                            digitalType: "2",
                            value: _vm.digitalValue[4],
                            title: "封闭车场泊位数",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "row4", attrs: { span: 6 } },
                      [
                        _c("digital-scroll", {
                          attrs: {
                            digitalType: "2",
                            value: _vm.digitalValue[5],
                            title: "路内车场数",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "row4", attrs: { span: 6 } },
                      [
                        _c("digital-scroll", {
                          attrs: {
                            digitalType: "2",
                            value: _vm.digitalValue[6],
                            title: "路内车场泊位数",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { staticStyle: { height: "100%" }, attrs: { span: 6 } },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("dv-scroll-board", {
                      ref: "indexFlowScrollBoard",
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { config: _vm.scrollBoardConfig },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-col",
              { staticStyle: { height: "100%" }, attrs: { span: 6 } },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("my-video-player", {
                      attrs: { height: "100%", width: "100%" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-row",
          { staticClass: "row-padding row3", attrs: { gutter: 36 } },
          [
            _c(
              "el-col",
              { staticClass: "row4", attrs: { span: 12 } },
              [
                _c(
                  "el-row",
                  { staticClass: "row-padding row2", attrs: { gutter: 36 } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "row4", attrs: { span: 12 } },
                      [
                        _c("bar-chart", {
                          attrs: { height: "100%", width: "100%" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "row4", attrs: { span: 12 } },
                      [_c("pie-chart")],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "row-padding row2", attrs: { gutter: 36 } },
                  [
                    _c(
                      "el-col",
                      { staticStyle: { height: "100%" }, attrs: { span: 24 } },
                      [
                        _c(
                          "div",
                          { staticClass: "content" },
                          [_c("line-chart")],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { staticClass: "row4", attrs: { span: 12 } },
              [
                _c("index-page-map", {
                  attrs: { height: "100%", width: "100%" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }