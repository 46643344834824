<template>
  <div class="login">
    <div class="video-container">
      <div class="filter">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
          <h3 class="title">{{title}}</h3>
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
              <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="密码"
              @keyup.enter.native="handleLogin">
              <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
            </el-input>
          </el-form-item>
          <el-form-item prop="code" v-if="captchaEnabled">
            <el-input v-model="loginForm.code" auto-complete="off" placeholder="验证码" style="width: 63%"
              @keyup.enter.native="handleLogin">
              <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
            </el-input>
            <div class="login-code">
              <img :src="codeUrl" @click="getCode" class="login-code-img" />
            </div>
          </el-form-item>
          <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
          <el-form-item style="width:100%;">
            <el-button :loading="loading" size="medium" type="primary" style="width:100%;" @click.native.prevent="handleLogin">
              <span v-if="!loading">登 录</span>
              <span v-else>登 录 中...</span>
            </el-button>
            <div style="float: right;" v-if="register">
              <router-link class="link-type" :to="'/register'">立即注册</router-link>
            </div>
          </el-form-item>
        </el-form>
        <!--  底部  -->
        <div class="el-login-footer">
          <span>{{copyright}}</span>
          <span><a href="https://beian.miit.gov.cn/" target="_blank">{{ beian }}</a></span>
          
        </div>
      </div>
      <video :style="fixStyle" autoplay loop class="fillWidth" muted>
        <source src="../assets/video/login.mp4" type="video/mp4" />
      </video>
      <!-- <div class="poster" v-if="!vedioCanPlay">
        <img :src="PATH_TO_JPEG" alt="背景">
      </div> -->
    </div>

  </div>
</template>

<script>
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt'

export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      loginForm: {
        // username: "admin",
        // password: "admin123",
        username: null,
        password: null,
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }]
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined,
      fixStyle: '',
      title: process.env.VUE_APP_TITLE,
      copyright: process.env.VUE_APP_COPYRIGHT,
      beian: process.env.VUE_APP_BEIAN
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created() {
    this.getCode();
    this.getCookie();
    this.onResizeHandler();
    console.log("export: ", this);
  },

  mounted() {
    window.onresize = () => {
      this.onResizeHandler();
    }
  },
  methods: {
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
            Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          this.$store.dispatch("Login", this.loginForm).then(() => {
            this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
          }).catch(() => {
            this.loading = false;
            if (this.captchaEnabled) {
              this.getCode();
            }
          });
        }
      });
    },
    onResizeHandler() {

        const windowWidth = document.body.clientWidth;
        const windowHeight = document.body.clientHeight;
        const windowAspectRatio = windowHeight / windowWidth;
        let videoWidth;
        let videoHeight;
        if (windowAspectRatio < 0.5) {
          videoWidth = windowWidth;
          videoHeight = videoWidth * 0.5;
          this.fixStyle = {
            height: `${windowWidth * 0.5}px`,
            width: `${windowWidth}px`,
            'margin-top': `${(windowHeight - videoHeight) / 2}px`,
            'margin-left': 'initial',
          };
        } else {
          videoHeight = windowHeight;
          videoWidth = videoHeight / 0.5;
          this.fixStyle = {
            height: `${windowHeight}px`,
            width: `${windowHeight / 0.5}px`,
            'margin-left': `${(windowWidth - videoWidth) / 2}px`,
            'margin-top': 'initial',
          };
        }
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
@import '../assets/styles/guanchao.scss';
// .login {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   // background-image: url("../assets/images/login-background.jpg");
//   background-image: url("../assets/images/login_bg_test.png");
//   background-size: cover;
// }

.login,
.video-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video-container .poster img {
  z-index: 0;
  position: absolute;
}

.video-container .filter {
  z-index: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fillWidth {
  width: 100%;
}

.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
  @include font_color("loginFormTitleColor");
  font-family: $ff1;
  font-size: $fs1;
  font-weight: $fw2;
  // color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  @include background_color("loginFormBgColor");
  width: 400px;
  padding: 25px 25px 5px 25px;
  justify-content: center;
  align-items: center;
  .el-input {
    height: 38px;

    input {
      height: 38px;
      @include background_color("loginFormInputBgColor");
      @include font_color("loginFormInputColor");
      @include border_color("loginFormInputBorderColor");
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 25px;
  line-height: 25px;
  position: fixed;
  bottom: 45px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
  display: flex;
  flex-direction:column;
}
.login-code-img {
  height: 38px;
}
</style>
