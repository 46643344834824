import request from '@/utils/request'

// 查询黑白灰名单列表
export function listBlacklist(query) {
	return request({
		url: '/park/blacklist/list',
		method: 'get',
		params: query
	})
}

// 查询黑白灰名单详细
export function getBlacklist(id) {
	return request({
		url: '/park/blacklist/' + id,
		method: 'get'
	})
}

// 新增黑白灰名单
export function addBlacklist(data) {
	return request({
		url: '/park/blacklist',
		method: 'post',
		data: data
	})
}

// 修改黑白灰名单
export function updateBlacklist(data) {
	return request({
		url: '/park/blacklist',
		method: 'put',
		data: data
	})
}

// 删除黑白灰名单
export function delBlacklist(id) {
	return request({
		url: '/park/blacklist/' + id,
		method: 'delete'
	})
}


