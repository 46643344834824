
const state = {
    dhWsMap: {},
    deviceDhWsMap: {},
    initStatus: 0, // 0-未初始化；1-已初始化； 2-需要重新初始化
    showDevice: null,
}
const mutations = {
    SET_DHWS: (state, { id, dhWs }) => {
        if (id !== null && id !== "") {
            state.dhWsMap[id] = dhWs
        }
        console.log("setDhWsMap:", id, dhWs, state.dhWsMap)
    },
    REMOVE_DHWS: (state, id) => {
        try {
            if (id in state.dhWsMap) {
                state.dhWsMap[id] = null
            }
        } catch (e) {
        }
    },
    SET_INIT_STATUS: (state, status) => {
        state.initStatus = status
    },
    BIND_DEVICE_DHWS: (state, {deviceId, dhWs}) => {
        if (deviceId != null) {
            state.deviceDhWsMap[deviceId] = dhWs;
        }
    },
    SET_SHOW_DEVICE: (state, deviceId) => {
        state.showDevice = deviceId
    }
}

const actions = {
    // 设置
    setDhws({ commit }, data) {
        commit('SET_DHWS', data)
    },
    // 删除
    removeDhws({ commit }, id) {
        commit('REMOVE_DHWS', id)
    },
    // 初始化状态
    setInitStatus({ commit }, status) {
        commit('SET_INIT_STATUS', status)
    },
    bindDeviceDhws({ commit}, data) {
        commit('BIND_DEVICE_DHWS', data)
    },
    setShowDevice({ commit }, dv) {
        commit('SET_SHOW_DEVICE', dv)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

