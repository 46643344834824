import request from '@/utils/request'

// 查询订单列表
export function listOrder(query) {
	return request({
		url: '/pay/order/list',
		method: 'get',
		params: query
	})
}

// 查询订单详细
export function getOrder(id) {
	return request({
		url: '/pay/order/' + id,
		method: 'get'
	})
}

// 新增订单
export function addOrder(data) {
	return request({
		url: '/pay/order',
		method: 'post',
		data: data
	})
}

// 修改订单
export function updateOrder(data) {
	return request({
		url: '/pay/order',
		method: 'put',
		data: data
	})
}

// 删除订单
export function delOrder(id) {
	return request({
		url: '/pay/order/' + id,
		method: 'delete'
	})
}

// 汇总信息
export function refreshSummary(query) {
    return request({
        url: '/pay/order/refreshSummary',
        method: 'get',
        params: query
    })
}

export function getPayTypeStatistic(query) {
    return request({
        url: '/pay/order/statistic/payType',
        method: 'get',
        params: query
    })
}


// 订单退款
export function refundOrder(data) {
    return request({
        url: '/pay/order/refund/' + data.orderId,
        method: 'put',
        data:data
    })
}
