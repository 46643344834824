var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: { height: _vm.height, width: _vm.width },
      attrs: { id: "container_" + _vm.elIdReal },
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.showTitle))]),
      _c("div", { staticClass: "videoBox", style: _vm.fixStyle }, [
        _c("video", {
          attrs: {
            width: "100%",
            height: "100%",
            id: _vm.elIdReal,
            poster: require("../../assets/images/noVideo.svg"),
            muted: "",
            playsinline: "",
          },
          domProps: { muted: true },
        }),
        _c(
          "div",
          {
            staticClass: "playBg",
            attrs: { id: "mask_" + _vm.elIdReal },
            on: {
              mouseenter: _vm.showBtnHandle,
              mouseleave: _vm.hideBtnHandle,
            },
          },
          [
            _vm.showBtn
              ? _c(
                  "div",
                  {
                    staticClass: "playBtnG",
                    on: {
                      click: function ($event) {
                        return _vm.videoHandle()
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "playBtn",
                      attrs: {
                        "icon-class": _vm.playStatus
                          ? "video_pause"
                          : "video_play",
                      },
                    }),
                    _c("div", { staticClass: "outter" }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showBtn && _vm.showDeal
              ? _c(
                  "div",
                  {
                    staticClass: "playBtnG",
                    on: {
                      click: function ($event) {
                        return _vm.showDealHandle()
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "playBtn",
                      attrs: { "icon-class": "open_channel" },
                    }),
                    _c("div", { staticClass: "outter" }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }