import request from '@/utils/request'

// 查询月卡操作日志列表
export function parkRegionDataTreeSelect(parentId) {
    return request({
        url: '/park/statistic/parkRegionDataTreeSelect/' + parentId,
        method: 'get'
    })
}
// 获取收入值
export function getIncome(query) {
    return request({
        url: '/park/statistic/getIncome',
        method: 'get',
        params: query
    })
}
// 获取今日车流量
export function todayFlowCount(query) {
    return request({
        url: '/park/report/todayFlowCount',
        method: 'get',
        params: query
    })
}
// 获取今日订单数
export function todayOrderCount(query) {
    return request({
        url: '/park/report/todayOrderCount',
        method: 'get',
        params: query
    })
}
