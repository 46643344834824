import request from '@/utils/request'

// 查找某个计费规则根据计费规则组、车型、是否工作日
export function getFeeRuleItemFromServer(feeParkId, carType, workDay) {
    let query = {'feeParkId':feeParkId, 'carType':carType, 'workDay':workDay};
    return request({
        url: '/pay/fee/getFeeRuleItemFromServer',
        method: 'get',
        params: query
    })
}

// 查询计费规则组详细
export function getFeeGroupFromServer(feeParkId, carType) {
    let query = {'feeParkId':feeParkId, 'carType':carType};
    return request({
        url: '/pay/fee/getFeeGroupFromServer',
        method: 'get',
        params: query
    })
}

// 变更工作日非工作日计费规则
export function changeWorkAndNoWorkFeeId(feeParkId, carType, workDay) {
    let query = {'feeParkId':feeParkId, 'carType':carType, 'workDay':workDay};
    return request({
        url: '/pay/fee/changeWorkAndNoWorkFeeId',
        method: 'get',
        params: query
    })
}

// 修改计费规则组
export function updateFee(data) {
    return request({
        url: '/pay/fee',
        method: 'put',
        data: data
    })
}

// 查询组合支付列表
export function testFee(query) {
    return request({
        url: '/pay/fee/testFee',
        method: 'get',
        params: query
    })
}

// 查询自己所管辖的所有车场
export function getAllGovernmentParks() {
    return request({
        url: '/pay/fee/getAllGovernmentParks',
        method: 'get'
    })
}


// 分配停车场
export function payFeePark(query) {
    return request({
        url: '/pay/fee/payFeePark',
        method: 'get',
        params: query
    })
}

// 查询计费规则对应的所有停车场
export function feeParkMyParks(parkFeeId) {
    return request({
        url: '/pay/fee/feeParkMyParks/' + parkFeeId,
        method: 'get'
    })
}

// 查询计费规则对应的所有停车场
export function clearParkFee(parkFeeId, parkId) {
    return request({
        url: '/pay/fee/clearParkFee/' + parkFeeId + '/' + parkId,
        method: 'get'
    })
}


// 获取使用了指定计费规则的车场
export function feeParkList(feeId) {
    return request({
        url: '/pay/fee/all/list/' + feeId,
        method: 'get'
    })
}
//赋值车场计费规则
export function bindFeePark(params){
    return request({
        url: '/pay/fee/park/bind',
        method: 'put',
        params: params
    })
}



