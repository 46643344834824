<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch"
                 label-width="68px" v-if="!initQuery">
            <el-form-item label="车牌" prop="carNumber" label-width="60px">
                <el-input
                    v-model="queryParams.carNumber"
                    placeholder="请输入车牌"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="车牌颜色" prop="carNumberColor" label-width="95px">
                <el-select v-model="queryParams.carNumberColor" placeholder="请选择车牌颜色" style="width: 100%">
                    <el-option
                        v-for="dict in dict.type.park_carnumber_color_type"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="车场" prop="deptId" label-width="60px">
                <treeselect v-model="queryParams.deptId" :options="parkOptions" :show-count="true" placeholder="请选择车场"
                            style="width: 220px"/>
            </el-form-item>
            <el-form-item label="操作时间" prop="appealTime">
                <el-date-picker
                    v-model="createTime"
                    style="width: 335px"
                    type="datetimerange"
                    start-placeholder="操作开始时间"
                    end-placeholder="操作结束时间"
                    :default-time="['00:00:00', '23:59:59']"
                    @change="selectCreateTime"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="最小金额" prop="money" >
                <el-input v-model="queryParams.money"
                    placeholder="请输入最小金额"
                    clearable
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['park:specialmonthLog:export']"
                >导出
                </el-button>
            </el-col>
            <right-toolbar :showSearch.sync="showSearch" @queryTable="getList" v-if="!initQuery"></right-toolbar>
        </el-row>

        <el-row :gutter="10" class="mb8">
            <el-form label-width="100px" v-if="summaryBean">
                <el-col :span="2">
                    <el-form-item label="开卡金额：" prop="creatorMoney" style="width: 380px">
                        <span style="font-weight: bold">{{(summaryBean.creator?summaryBean.creator.summary/100.00:0)  + "元"}}</span>
                    </el-form-item>
                </el-col>

                <el-col :span="2">
                    <el-form-item label="续费金额：" prop="goonMoney" style="width: 380px">
                        <span style="font-weight: bold">{{(summaryBean.goon?summaryBean.goon.summary/100.00:0)  + "元"}}</span>
                </el-form-item>
                </el-col>
            </el-form>
        </el-row>

        <el-table v-loading="loading" :data="specialmonthLogList" @selection-change="handleSelectionChange">
            <el-table-column label="ID" align="center" prop="id"/>
            <el-table-column label="组织机构" align="center" prop="deptName"/>
            <el-table-column label="车牌号" align="center" prop="carNumber">
                <template slot-scope="scope">
                    <CarColor :carNumber="scope.row.carNumber" :carNumberColor="scope.row.carNumberColor"></CarColor>
                </template>
            </el-table-column>
            <el-table-column label="操作类型开卡还是续费" align="center" prop="type">
                <template slot-scope="scope">
                    <dict-tag :options="dict.type.park_special_month_opttype" :value="scope.row.type"/>
                </template>
            </el-table-column>
            <el-table-column label="金额(元)" align="center" prop="money">
                <template slot-scope="scope">
                    <span>￥{{scope.row.money/100}}</span>
                </template>
            </el-table-column>
            <el-table-column label="月卡id" align="center" prop="specialMonthId"/>
            <el-table-column label="操作员名称" align="center" prop="optionerName"/>
            <el-table-column label="收费员名称" align="center" prop="collectorName"/>
            <el-table-column label="时间" align="center" prop="createTime"/>
        </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />
    </div>
</template>

<script>
    import {
        addSpecialmonthLog,
        delSpecialmonthLog,
        getSpecialmonthLog,
        listSpecialmonthLog,
        updateSpecialmonthLog
    } from "@/api/park/specialmonthLog";
    import {treeselect as menuTreeSelect} from "@/api/system/dept";
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";
    import {formatDate} from "@/utils/index";

    export default {
        name: "SpecialmonthLog",
        dicts: ['park_special_month_opttype'],
        components: {Treeselect},
        data() {
            return {
                // 车场树选项
                parkOptions: undefined,

                initQuery:false,
                // 遮罩层
                loading: true,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // 月卡操作日志表格数据
                specialmonthLogList: [],
                // 汇总信息
                summaryBean:null,
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    orderByColumn: "id",
                    isAsc: "desc",
                    plateFuzzyMatch:true,
                    money:null,
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {
                },
                //操作时间范围
                createTime: null,
            };
        },
        created() {
            const query = this.$route.query;
            if(query && query.specialMonthId){
                this.initQuery = true;
                this.queryParams.specialMonthId = query.specialMonthId
            }else{
                this.initQuery = false;
            }

            this.getList();
            this.getParkTree();
        },
        methods: {
            /* 车场列表 */
            getParkTree() {
                menuTreeSelect().then(response => {
                    this.parkOptions = response.data;
                });
            },
            /** 查询月卡操作日志列表 */
            getList() {
                this.loading = true;
                listSpecialmonthLog(this.queryParams).then(response => {
                    this.specialmonthLogList = response.rows;
                    this.summaryBean = response.summaryBean;
                    this.total = response.total;
                    this.loading = false;
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    id: null,
                    type: null,
                    money: null,
                    specialMonthId: null,
                    carNumber: null,
                    carNumberColor: null,
                    optionerId: null,
                    optionerName: null,
                    collectorId: null,
                    collectorName: null,
                    createTime: null,
                };
                this.resetForm("form");
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.queryParams.createTimeStart = "";
                this.queryParams.createTimeEnd = "";
                this.createTime = [];
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id)
                this.single = selection.length !== 1
                this.multiple = !selection.length
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                this.open = true;
                this.title = "添加月卡操作日志";
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                const id = row.id || this.ids
                getSpecialmonthLog(id).then(response => {
                    this.form = response.data;
                    this.open = true;
                    this.title = "修改月卡操作日志";
                });
            },
            /** 提交按钮 */
            submitForm() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        if (this.form.id != null) {
                            updateSpecialmonthLog(this.form).then(response => {
                                this.$modal.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            });
                        } else {
                            addSpecialmonthLog(this.form).then(response => {
                                this.$modal.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                const ids = row.id || this.ids;
                this.$modal.confirm('是否确认删除月卡操作日志编号为"' + ids + '"的数据项？').then(function () {
                    return delSpecialmonthLog(ids);
                }).then(() => {
                    this.getList();
                    this.$modal.msgSuccess("删除成功");
                }).catch(() => {
                });
            },
            /** 导出按钮操作 */
            handleExport() {
                this.download('park/specialmonthLog/export', {
                    ...this.queryParams
                }, `specialmonthLog_${new Date().getTime()}.xlsx`)
            },

            /** 操作时间 **/
            selectCreateTime(time) {
                if (time && time.length > 0) {
                    this.queryParams.createTimeStart = formatDate(time[0]);
                    this.queryParams.createTimeEnd = formatDate(time[1]);
                } else {
                    this.queryParams.createTimeStart = "";
                    this.queryParams.createTimeEnd = "";
                }
            },
        }
    };
</script>
