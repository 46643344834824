// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/login-background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".register {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n.title {\n  margin: 0px auto 30px auto;\n  text-align: center;\n  color: #707070;\n}\n.register-form {\n  border-radius: 6px;\n  background: #ffffff;\n  width: 400px;\n  padding: 25px 25px 5px 25px;\n}\n.register-form .el-input {\n  height: 38px;\n}\n.register-form .el-input input {\n  height: 38px;\n}\n.register-form .input-icon {\n  height: 39px;\n  width: 14px;\n  margin-left: 2px;\n}\n.register-tip {\n  font-size: 13px;\n  text-align: center;\n  color: #bfbfbf;\n}\n.register-code {\n  width: 33%;\n  height: 38px;\n  float: right;\n}\n.register-code img {\n  cursor: pointer;\n  vertical-align: middle;\n}\n.el-register-footer {\n  height: 25px;\n  line-height: 25px;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  text-align: center;\n  color: #fff;\n  font-family: Arial;\n  font-size: 12px;\n  letter-spacing: 1px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n.register-code-img {\n  height: 38px;\n}", ""]);
// Exports
exports.locals = {
	"theme-default_commonDialogBg": "#ffffff",
	"theme-default_commonDialogText": "#1a2138",
	"theme-default_commonDialogBorder": "#edf1f7",
	"theme-default_commonDialogShadow": "#f7f9fc",
	"theme-default_primary": "#1890ff",
	"theme-default_primaryLight": "#598bff",
	"theme-default_primaryBtnText": "#ffffff",
	"theme-dark_loginFormBgColor": "#222b45",
	"theme-dark_loginFormTitleColor": "#ffffff",
	"theme-dark_loginFormInputBgColor": "#1a2138",
	"theme-dark_loginFormInputColor": "#8f9bb3",
	"theme-dark_loginFormInputBorderColor": "#101426",
	"theme-dark_commonBg": "#222b45",
	"theme-dark_commonBg2": "#1a2138",
	"theme-dark_commonBg3": "#151a30",
	"theme-dark_commonBg4": "#101426",
	"theme-dark_commonBorder": "#222b45",
	"theme-dark_commonBorder2": "#1a2138",
	"theme-dark_commonBorder3": "#151a30",
	"theme-dark_commonBorder4": "#101426",
	"theme-dark_commonBorder5": "#101426",
	"theme-dark_commonFg": "#8f9bb3",
	"theme-dark_commonFgHeading": "#ffffff",
	"theme-dark_commonFgText": "#ffffff",
	"theme-dark_commonFgHighlight": "#3366ff",
	"theme-dark_commonLayoutBg": "#1b1b38",
	"theme-dark_commonSeparator": "#1b1b38",
	"theme-dark_commonDialogBg": "#222b45",
	"theme-dark_commonDialogText": "#ffffff",
	"theme-dark_commonDialogBorder": "#151a30",
	"theme-dark_commonDialogShadow": "#1a2138",
	"theme-dark_primary": "#3366ff",
	"theme-dark_success": "#00d68f",
	"theme-dark_info": "#0095ff",
	"theme-dark_warning": "#ffaa00",
	"theme-dark_danger": "#ff3d71",
	"theme-dark_primaryLight": "#598bff",
	"theme-dark_successLight": "#2ce69b",
	"theme-dark_infoLight": "#42aaff",
	"theme-dark_warningLight": "#ffc94d",
	"theme-dark_dangerLight": "#ff708d",
	"theme-dark_primaryBtnText": "#ffffff",
	"theme-dark_successBtnText": "#ffffff",
	"theme-dark_infoBtnText": "#ffffff",
	"theme-dark_warningBtnText": "#ffffff",
	"theme-dark_dangerBtnText": "#ffffff",
	"theme-cosmic_loginFormBgColor": "#323259",
	"theme-cosmic_loginFormTitleColor": "#ffffff",
	"theme-cosmic_loginFormInputBgColor": "#252547",
	"theme-cosmic_loginFormInputColor": "#b4b4db",
	"theme-cosmic_loginFormInputBorderColor": "#13132b",
	"theme-cosmic_commonBg": "#323259",
	"theme-cosmic_commonBg2": "#252547",
	"theme-cosmic_commonBg3": "#1b1b38",
	"theme-cosmic_commonBg4": "#13132b",
	"theme-cosmic_commonBorder": "#323259",
	"theme-cosmic_commonBorder2": "#252547",
	"theme-cosmic_commonBorder3": "#1b1b38",
	"theme-cosmic_commonBorder4": "#13132b",
	"theme-cosmic_commonBorder5": "#13132b",
	"theme-cosmic_commonFg": "#b4b4db",
	"theme-cosmic_commonFgHeading": "#ffffff",
	"theme-cosmic_commonFgText": "#ffffff",
	"theme-cosmic_commonFgHighlight": "#a16eff",
	"theme-cosmic_commonLayoutBg": "#151a30",
	"theme-cosmic_commonSeparator": "#151a30",
	"theme-cosmic_commonDialogBg": "#323259",
	"theme-cosmic_commonDialogText": "#ffffff",
	"theme-cosmic_commonDialogBorder": "#1b1b38",
	"theme-cosmic_commonDialogShadow": "#252547",
	"theme-cosmic_primary": "#a16eff",
	"theme-cosmic_success": "#00d68f",
	"theme-cosmic_info": "#0095ff",
	"theme-cosmic_warning": "#ffaa00",
	"theme-cosmic_danger": "#ff3d71",
	"theme-cosmic_primaryLight": "#b18aff40",
	"theme-cosmic_successLight": "#2ce69b40",
	"theme-cosmic_infoLight": "#42aaff40",
	"theme-cosmic_warningLight": "#ffc94d40",
	"theme-cosmic_dangerLight": "#ff708d40",
	"theme-cosmic_primaryBtnText": "#ffffff",
	"theme-cosmic_successBtnText": "#ffffff",
	"theme-cosmic_infoBtnText": "#ffffff",
	"theme-cosmic_warningBtnText": "#ffffff",
	"theme-cosmic_dangerBtnText": "#ffffff",
	"theme-material-light_loginFormBgColor": "#ffffff",
	"theme-material-light_loginFormTitleColor": "#1a2138",
	"theme-material-light_loginFormInputBgColor": "#f5f5f5",
	"theme-material-light_loginFormInputColor": "#838383",
	"theme-material-light_loginFormInputBorderColor": "#e0e0e0",
	"theme-material-light_commonBg": "#ffffff",
	"theme-material-light_commonBg2": "#f5f5f5",
	"theme-material-light_commonBg3": "#ebebeb",
	"theme-material-light_commonBg4": "#e0e0e0",
	"theme-material-light_commonBorder": "#ffffff",
	"theme-material-light_commonBorder2": "#f5f5f5",
	"theme-material-light_commonBorder3": "#ebebeb",
	"theme-material-light_commonBorder4": "#e0e0e0",
	"theme-material-light_commonBorder5": "#b3b3b3",
	"theme-material-light_commonFg": "#838383",
	"theme-material-light_commonFgHeading": "#1a2138",
	"theme-material-light_commonFgText": "#1a2138",
	"theme-material-light_commonFgHighlight": "#6200ee",
	"theme-material-light_commonLayoutBg": "#ebebeb",
	"theme-material-light_commonSeparator": "#ebebeb",
	"theme-material-light_commonDialogBg": "#ffffff",
	"theme-material-light_commonDialogText": "#1a2138",
	"theme-material-light_commonDialogBorder": "#ebebeb",
	"theme-material-light_commonDialogShadow": "#f5f5f5",
	"theme-material-light_primary": "#6200ee",
	"theme-material-light_success": "#60af20",
	"theme-material-light_info": "#0495ee",
	"theme-material-light_warning": "#ff9f05",
	"theme-material-light_danger": "#b00020",
	"theme-material-light_primaryLight": "#903df4",
	"theme-material-light_successLight": "#8fcf50",
	"theme-material-light_infoLight": "#40bbf4",
	"theme-material-light_warningLight": "#ffbe43",
	"theme-material-light_dangerLight": "#cf3341",
	"theme-material-light_primaryBtnText": "#ffffff",
	"theme-material-light_successBtnText": "#ffffff",
	"theme-material-light_infoBtnText": "#ffffff",
	"theme-material-light_warningBtnText": "#ffffff",
	"theme-material-light_dangerBtnText": "#ffffff",
	"theme-material-dark_loginFormBgColor": "#383838",
	"theme-material-dark_loginFormTitleColor": "#ffffff",
	"theme-material-dark_loginFormInputBgColor": "#292929",
	"theme-material-dark_loginFormInputColor": "#808080",
	"theme-material-dark_loginFormInputBorderColor": "#141414",
	"theme-material-dark_commonBg": "#383838",
	"theme-material-dark_commonBg2": "#292929",
	"theme-material-dark_commonBg3": "#1f1f1f",
	"theme-material-dark_commonBg4": "#141414",
	"theme-material-dark_commonBorder": "#383838",
	"theme-material-dark_commonBorder2": "#292929",
	"theme-material-dark_commonBorder3": "#1f1f1f",
	"theme-material-dark_commonBorder4": "#141414",
	"theme-material-dark_commonBorder5": "#141414",
	"theme-material-dark_commonFg": "#808080",
	"theme-material-dark_commonFgHeading": "#ffffff",
	"theme-material-dark_commonFgText": "#ffffff",
	"theme-material-dark_commonFgHighlight": "#e91d63",
	"theme-material-dark_commonLayoutBg": "#1f1f1f",
	"theme-material-dark_commonSeparator": "#1f1f1f",
	"theme-material-dark_commonDialogBg": "#383838",
	"theme-material-dark_commonDialogText": "#ffffff",
	"theme-material-dark_commonDialogBorder": "#1f1f1f",
	"theme-material-dark_commonDialogShadow": "#292929",
	"theme-material-dark_primary": "#e91d63",
	"theme-material-dark_success": "#60af20",
	"theme-material-dark_info": "#0495ee",
	"theme-material-dark_warning": "#ff9f05",
	"theme-material-dark_danger": "#b00020",
	"theme-material-dark_primaryLight": "#f24681",
	"theme-material-dark_successLight": "#8fcf50",
	"theme-material-dark_infoLight": "#40bbf4",
	"theme-material-dark_warningLight": "#ffbe43",
	"theme-material-dark_dangerLight": "#cf3341",
	"theme-material-dark_primaryBtnText": "#ffffff",
	"theme-material-dark_successBtnText": "#ffffff",
	"theme-material-dark_infoBtnText": "#ffffff",
	"theme-material-dark_warningBtnText": "#ffffff",
	"theme-material-dark_dangerBtnText": "#ffffff"
};
module.exports = exports;
